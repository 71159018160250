<template>
  <v-container dark class="container pa-0 ma-0" fluid>
    <v-flex v-if="!$vuetify.breakpoint.smAndDown" xs12 md6>
      <div
        style="display: flex; width: 100%; height: 100vh; background-color: #fff;"
      >
        <v-img width="100%" src="img/login_image.jpg" alt="">
          <div
            class="expande-horizontal"
            style="height: 100vh; background-color: rgba(0,0,0,0.5);"
          >
            <div class="expande-horizontal pa-6">
              <v-spacer></v-spacer>
              <!-- <img height="60" class="ma-6" src="img/logo.png" alt="" /> -->
            </div>
          </div>
        </v-img>
      </div>
    </v-flex>
    <v-flex xs12 md6>
      <div
        style="height: 100vh; background: #e5e5e5;"
        class="expande-horizontal centraliza column"
      >
        <FormLogin />
      </div>
    </v-flex>
    <v-dialog transition="slide-x-transition" v-model="splashscreen" fullscreen>
      <div class="expande-horizontal centraliza column" style="background: #f7f7f7; min-height: 100vh; overflow: hidden">
        <v-flex xs8 md2>
          <v-img contain height="200" src="login.png" alt="splash" />
        </v-flex>
        <v-flex xs8 md2>
          <v-progress-circular
            indeterminate
            color="primary"
            size="34"
          ></v-progress-circular>
        </v-flex>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FormLogin from "./components/FormLogin";

export default {
  data() {
    return {
      splashscreen: true,
    }
  },
  components: {
    ...mapGetters(["loggedUser"]),
    FormLogin
  },
  created() {
    if (this.loggedUser) {
      this.$router.push("/dashboard");
      //implementar toda da permissão
    }
    if (cordova) {
      if (cordova.platformId == "android") {
        StatusBar.backgroundColorByHexString(this.$theme.secondary);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.splashscreen = false;
    }, 2000);
  }
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: flex-start/;
  flex-direction: row;
  background-color: #fff;
  height: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.logo {
  align-self: stretch;
}

.img-checkout {
  align-self: center;
}
</style>
